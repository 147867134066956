import { generateId, sleep, validateSlug } from "@/util";
import { BaseServiceError } from "./BaseServiceError";
import FirebaseService from "./FirebaseService";
import firebase from "firebase/app";
import { UnresolvedMeeting } from "@/models/Meeting";
import { UnresolvedVideo } from "@/models/Video";
import { UnresolvedChatMessage } from "@/models/ChatMessage";
import { UnresolvedParticipant } from "@/models/Participant";
import { Store } from "vuex";
import store, { YacXStore } from "@/store";

export default class MeetingService extends FirebaseService {

  async createMiroMeeting():Promise<{id: string, slug: string}> {
    try {
      
      const dateComponent = new Date().toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
      const probableName = `Miro Meeting - ${dateComponent}`;
      const name = probableName;
      const slug = await this.getUniqueSlug();
      const id = generateId(8);
      const date = new Date();

      const meeting = {
        slug,
        name: name.trim(),
        id,
        notes: "",
        domain: location.hostname,
        agendaItems: [],
        videoIndex: [],
        status: "OPEN",
        dateCreated: date,
        endNotes: "",
      };

      const meetingDoc = this.meetingsCol.doc(id);
      await meetingDoc.set(meeting);
      return {id, slug};

    } catch (error) {
      console.error("MeetingService.createMeeting", error);
      if (error instanceof MeetingServiceError) {
        throw error;
      } else {
        throw new MeetingServiceError("COULD_NOT_CREATE_MEETING");
      }
    }
  }

  async getUniqueSlug():Promise<string>{
    let slug = generateId(6);

    const found = false;
    while (!found) {
      const qs = await this.meetingsCol
        .where("slug", "==", slug)
        .limit(1)
        .get();
      const [existing] = qs.docs;
      if (!existing) {
        return slug;
      } else {
        slug = generateId(6);
      }
    }
    return "";
  }

  private async uploadVideo({
    file,
    refPath,
  }: {
    file: File;
    refPath: string;
  }): Promise<string> {
    return new Promise((resolve, reject) => {
      const storageRef = this.storage.ref(refPath);
      const [fileType] = (file.type || "").split(";");
      const contentType = fileType.replace("x-matroska", "webm");
      let extension = contentType.split("/").pop() || "webm";
      extension = extension.split("-").pop() || "webm";

      const uploadTask = storageRef.put(file, {
        contentType,
        customMetadata: {
          mimeType: fileType.replace("x-matroska", "webm"),
        },
      });
      uploadTask.on(
        "state_changed",
        // (snapshot) => {
        //   const progress =
        //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //   onUploadProgress(progress);
        // },
        ()=>{},
        (error) => {
          console.error("MeetingService.uploadVideo", error);
          reject(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  }

  async addMiroVideo({
    file,
    byUserId,
    miroBoard,
    miroUser,
    duration,
    kickoff,
    meeting
  }: {
    file: File;
    byUserId: string;
    miroBoard: string;
    miroUser: string;
    // onUploadProgress: this.onUploadProgress,
    duration: number;
    // store: this.$store,
    kickoff: boolean;
    meeting: {id: string, slug: string};
  }) {
    let url: string = "";
    const videoId = Date.now() + generateId(6);
    const refPath: string = `meetings/${meeting.id}/videos/raw__${videoId}.${
      file.type.includes("mp4") ? "mp4" : "webm"
    }`;
    store.commit("meeting/uploading", true);
    try {
      url = await this.uploadVideo({
        file,
        refPath,
      });
    } catch (error) {
      console.error("MeetingService.addVideo", error);
      throw new MeetingServiceError("COULD_NOT_UPLOAD_VIDEO");
    }
    try {
      const batch = this.db.batch();
      batch.update(this.meetingsCol.doc(meeting.id), {
        videoIndex: firebase.firestore.FieldValue.arrayUnion(videoId),
      });
      
      const videoDoc = {
        id: videoId,
        title: "",
        url,
        refPath,
        video: false,
        by: this.usersCol.doc(byUserId) as any,
        miroBoard: miroBoard,
        miroUser: miroUser,
        status: "UPLOADED",
        duration,
        type: "VOICE",
        sentAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };
      batch.set(
        this.meetingsCol.doc(meeting.id).collection("videos").doc(videoId),
        videoDoc
      );
      await batch.commit();
      console.log('UPLOADED VIDEO', meeting.id, meeting.slug, videoId);
    } catch (error) {
      console.error("MeetingService.addVideo", error);
      throw new MeetingServiceError("COULD_NOT_ADD_VIDEO");
    }
    store.commit("meeting/uploading", false);
  }

  async setVideo(
    meetingId: string,
    videoId: string,
    payload: Partial<Pick<UnresolvedVideo, "title">>
  ) {
    try {
      await this.meetingsCol
        .doc(meetingId)
        .collection("videos")
        .doc(videoId)
        .update(payload);
    } catch (error) {
      console.error("MeetingService.setVideo", error);
      throw new MeetingServiceError("COULD_NOT_SET_VIDEO");
    }
  }

  async deleteVideo(meetingId: string, videoId: string) {
    try {
      await this.meetingsCol
        .doc(meetingId)
        .collection("videos")
        .doc(videoId)
        .delete();
    } catch (error) {
      console.error("MeetingService.deleteVideo", error);
      throw new MeetingServiceError("COULD_NOT_DELETE_VIDEO");
    }
  }

  async setVideoIndex(meetingId: string, newIndex: string[]) {
    try {
      const payload: Pick<UnresolvedMeeting, "videoIndex"> = {
        videoIndex: newIndex,
      };
      await this.meetingsCol.doc(meetingId).update(payload);
    } catch (error) {
      console.error("MeetingService.setVideoIndex", error);
      throw new MeetingServiceError("COULD_NOT_SET_VIDEO_INDEX");
    }
  }

  async setAgendaItems(
    meetingId: string,
    agendaItems: UnresolvedMeeting["agendaItems"]
  ) {
    try {
      await this.meetingsCol.doc(meetingId).update({
        agendaItems,
      });
    } catch (error) {
      console.error("MeetingService.setAgendaItems", error);
      throw new MeetingServiceError("COULD_NOT_SET_AGENDA_ITEMS");
    }
  }

  async sendChatMessage(
    meetingId: string,
    payload: { text: string; files?: File[] },
    byUserId: string
  ) {
    try {
      const id = Date.now() + "_" + generateId(3);
      const chatMessage: UnresolvedChatMessage = {
        id,
        ...payload,
        by: this.usersCol.doc(byUserId) as any,
        sentAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };
      await this.meetingsCol
        .doc(meetingId)
        .collection("chat")
        .doc(id)
        .set(chatMessage);
    } catch (error) {
      console.error("MeetingService.sendChatMessage", error);
      throw new MeetingServiceError("COULD_NOT_SEND_CHAT_MESSAGE");
    }
  }

  async setMeetingNotes(meetingId: string, notes: string) {
    try {
      await this.meetingsCol.doc(meetingId).update({
        notes,
      });
    } catch (error) {
      console.error("MeetingService.setMeetingNotes", error);
      throw new MeetingServiceError("COULD_NOT_SET_MEETING_NOTES");
    }
  }
}

export type MeetingServiceErrorCode =
  | "COULD_NOT_CREATE_MEETING"
  | "COULD_NOT_ARCHIVE_MEETING"
  | "COULD_NOT_UNARCHIVE_MEETING"
  | "COULD_NOT_UPDATE_MEETING"
  | "COULD_NOT_UPLOAD_VIDEO"
  | "COULD_NOT_ADD_VIDEO"
  | "COULD_NOT_SET_VIDEO"
  | "COULD_NOT_DELETE_VIDEO"
  | "COULD_NOT_SET_VIDEO_INDEX"
  | "COULD_NOT_SET_AGENDA_ITEMS"
  | "COULD_NOT_SEND_CHAT_MESSAGE"
  | "COULD_NOT_ADD_MEETING_PARTICIPANT"
  | "SLUG_ALREADY_EXISTS"
  | "SLUG_MISSING"
  | "NAME_MISSING"
  | "INVALID_SLUG"
  | "COULD_NOT_SET_MEETING_NOTES"
  | "COULD_NOT_ADD_END_NOTES";

export class MeetingServiceError extends BaseServiceError<MeetingServiceErrorCode> {
  mapErrorCodeToMessage(Code: MeetingServiceErrorCode): string {
    switch (Code) {
      case "COULD_NOT_CREATE_MEETING":
        return "Meeting creation failed.";
      case "COULD_NOT_ARCHIVE_MEETING":
        return "Archiving the meeting failed.";
      case "COULD_NOT_UNARCHIVE_MEETING":
        return "Could not unarchive meeting.";
      case "COULD_NOT_UPDATE_MEETING":
        return "Could not update meeting.";
      case "COULD_NOT_UPLOAD_VIDEO":
        return "Could not upload video.";
      case "COULD_NOT_ADD_VIDEO":
        return "Could not add video to meeting.";
      case "COULD_NOT_DELETE_VIDEO":
        return "Could not delete video.";
      case "COULD_NOT_SET_VIDEO_INDEX":
        return "Could not set video index.";
      case "COULD_NOT_SET_AGENDA_ITEMS":
        return "Could not set agenda items.";
      case "COULD_NOT_SEND_CHAT_MESSAGE":
        return "Could not send chat message.";
      case "COULD_NOT_SET_MEETING_NOTES":
        return "Could not set meeting notes.";
      case "COULD_NOT_SET_VIDEO":
        return "Could not set video data.";
      case "COULD_NOT_ADD_MEETING_PARTICIPANT":
        return "Could not add meeting participant.";
      case "SLUG_ALREADY_EXISTS":
        return "That link is in use.";
      case "SLUG_MISSING":
        return "You must provide a link.";
      case "NAME_MISSING":
        return "Meetings are better with titles";
      case "INVALID_SLUG":
        return "Invalid link.";
      case "COULD_NOT_ADD_END_NOTES":
        return "Adding the summary failed. Sorry!";
      default:
        return "There has been an unknown error.";
    }
  }
}
