













































import { mapState } from "vuex";
// import { app } from "@/services/FirebaseService";
import Vue from "vue";
import MicIcon from "@/components/svg/MicIcon.vue";
const { board } = window.miro;

export default Vue.extend<any, any, any, any>({
  name: "Meeting",
  components: {
    MicIcon
  },
  data() {
    return {
      title: "Yac & Miro | Record Message",
      
      sendingMessagePercent: 0,
      sendingMessageError: "",

      recording: false,
      processing: false,
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          vmid: 'description',
          content:  "Listen & Reply to this meeting on Yac"
        }
      ]
    }
  },
  filters:{
    formatAsTimecode(v:number) {
      function transform(secs: number) {
        var hours = Math.floor(secs / (60 * 60));
        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
        var obj = {
          h: hours,
          m: minutes,
          s: seconds,
        };
        return `
          ${obj.m < 10 ? "0" : ""}${obj.m}:${obj.s < 10 ? "0" : ""}${obj.s}
        `;
      }
      if (!v) return "00:00";
      return transform(v);
    },
  },
  computed: {
    ...mapState("recording", ["devices","defaultDeviceId","isRecording", "duration"]),
    ...mapState({user: (state) => (state as any).user.user}),
  },
  watch:{
    "$store.state.recording.readyForUpload": function(ready){
      if(ready){
        this.sendMessage();
      }
    },
  },
  methods: {
    startRecording(){
      this.recording = true;
      this.$store.dispatch("recording/startAudioRecording");
    },
    discard(){
        board.ui.closeModal();
    },
    async upload(){
        this.recording = false;
        this.processing = true;
        this.$store.dispatch("recording/stopRecording");
    },
    onUploadProgress(v: number) {
      this.sendingMessagePercent = v;
    },
    async sendMessage() {
      this.sendingMessageError = "";      
      try {
        const boardInfo = await board.getInfo();
        const userInfo = await board.getUserInfo();

        const file = await this.$store.getters['recording/getEncodedFile']();

        const meeting = await this.$store.dispatch("meeting/createMiroMeeting");
        await this.$store.dispatch("meeting/addMiroRecording", {
          file: file,
          miroBoard: boardInfo.id,
          miroUser: userInfo.id,
          byUserId: this.user.id,
          duration: this.$store.state.recording.duration,
          kickoff: true,
          type: "VOICE",
          meeting
        });

        board.createEmbed({
            url: `https://beta.yac.com/m/${meeting.slug}`,
            width: 1280,
            height: 720
        }).then(()=>{
            board.ui.closeModal();
        });

      } catch (err: any) {
        console.log("ERROR", err);
        this.sendingMessageError = err.message || "Could not send message.";
      } finally {
        this.$store.dispatch("recording/reset");
        this.messageToSendTitle = "";
      }
    },
    discardMessage() {
      this.recorder.isPreviewing = false;
      this.recorder.duration = 0;
      this.recorder.recordedData = null;
    }
  },
});
/*
//MIRO EMBED

*/
