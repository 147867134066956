import FirebaseService from "./FirebaseService";
import firebase from "firebase/app";
import { BaseServiceError } from "./BaseServiceError";
import {
  assign,
  generateId,
  getHash,
  Nullable,
  parseUndefIntoNull,
  validateEmail,
} from "@/util";
import { UnresolvedUser } from "../models/User";
import UniqueNameService from "./UniqueNameService";

export type UserInputModel = Omit<UnresolvedUser, "created"> & {
  created?: firebase.firestore.FieldValue;
};

export type UserOutputModel = Omit<UnresolvedUser, "created"> &
  Partial<Pick<UnresolvedUser, "created">>;

export default class UserService extends FirebaseService {
  public paidPlans = ["PRO"];

  validateEmail(email: string) {
    if (validateEmail(email)) return;
    throw new UserServiceError("INVALID_EMAIL");
  }
  async loadUser(
    firebaseUser: Partial<
      Pick<
        firebase.User,
        "email" | "displayName" | "photoURL" | "isAnonymous" | "uid"
      >
    >,
    options: {
      prioritizeFirebaseUserFields?: boolean;
      overrides?: Partial<UserInputModel>;
      attemptMergeIntoCurrentUser?: boolean;
      currentUserId?: string;
      removeUserWithId?: string;
    } = {}
  ): Promise<UserOutputModel> {
    try {
      const { overrides: fireOverrides = {} } = options;
      const email = (fireOverrides.email || firebaseUser.email || "")
        .toLowerCase()
        .trim();
      if (email) this.validateEmail(email);
      if (!email && !firebaseUser.uid) throw new Error();

      let userDocument = await this.getUserDocument(
        {
          email,
          uid: firebaseUser.uid,
        }
      );

      const userDocumentEmail = userDocument
        ? (userDocument.data() || {}).email || ""
        : "";

      if (
        !userDocumentEmail &&
        options.attemptMergeIntoCurrentUser &&
        options.currentUserId
      ) {
        const probableUserDocument = await this.usersCol
          .doc(options.currentUserId)
          .get();
        if (probableUserDocument.exists) {
          try {
            await this.updateUser(options.currentUserId, {
              email,
            });
            userDocument = (await this.usersCol
              .doc(options.currentUserId)
              .get()) as firebase.firestore.DocumentSnapshot<UnresolvedUser>;
            console.log("merged user", userDocument.data());
          } catch (error) {
            console.error(
              "UserService.loadUser attemptMergeIntoCurrentUser",
              error
            );
          }
        }
      }

      if (!userDocument) {
        userDocument = await this.getUserDocument(
          {
            email,
            uid: firebaseUser.uid,
          }
        );
      }

      const userPayload = await this.constructUserPayload(
        userDocument ? userDocument.data() || {} : {},
        firebaseUser || {},
        options
      );

      await this.usersCol.doc(userPayload.id).set(userPayload, { merge: true });

      if (options.removeUserWithId) {
        const userToRemoveQs = (await this.usersCol
          .where("id", "==", options.removeUserWithId)
          .get()) as firebase.firestore.QuerySnapshot<UnresolvedUser>;
        const masterUserQs = (await this.usersCol
          .doc(userPayload.id)
          .get()) as firebase.firestore.DocumentSnapshot<UnresolvedUser>;
        if (userToRemoveQs.size && masterUserQs.exists) {
          // await this.removeUserDuplicates(userToRemoveQs.docs, masterUserQs);
        }
      }

      return userPayload as UserOutputModel;
    } catch (error) {
      if (error instanceof UserServiceError) throw error;
      throw new UserServiceError("COULD_NOT_LOAD_USER");
    }
  }

  private async constructUserPayload(
    userDocument: Partial<UnresolvedUser>,
    firebaseUser: Partial<
      Pick<
        firebase.User,
        "email" | "displayName" | "photoURL" | "isAnonymous" | "uid"
      >
    >,
    options: {
      prioritizeFirebaseUserFields?: boolean;
      overrides?: Partial<UserInputModel>;
    } = {}
  ) {
    const existing = userDocument;
    const { overrides: fireOverrides = {}, prioritizeFirebaseUserFields } =
      options;

    type UserInputPayload = { uid: string } & Nullable<Partial<UserInputModel>>;

    let base = {} as UserInputPayload;
    const fireWithOverrides = assign<UserInputPayload>(
      {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        photoURL: firebaseUser.photoURL,
        isAnonymous: firebaseUser.isAnonymous,
        realName: prioritizeFirebaseUserFields ? firebaseUser.displayName : "",
      },
      fireOverrides
    );
    if (prioritizeFirebaseUserFields) {
      base = assign(existing as UserInputPayload, fireWithOverrides);
    } else base = assign(fireWithOverrides, existing as UserInputPayload);

    if (existing && existing.created) delete base.created;

    const emailHash = base.email ? getHash(base.email) : undefined;
    const defaultDisplayName =
      (await this.getGravatarDisplayName(emailHash)) ||
      this.getRandomDisplayName();
    const defaultPhotoURL = this.getDefaultPhotoURL(
      defaultDisplayName,
      emailHash
    );
    const defaultSlug = generateId(9);
    const defaultId = generateId(9);
    const defaults: Pick<
      UserInputPayload,
      "id" | "photoURL" | "displayName" | "slug" | "isAnonymous" | "uids"
    > = {
      id: defaultId,
      photoURL: defaultPhotoURL,
      displayName: defaultDisplayName,
      slug: defaultSlug,
      isAnonymous: !!base.isAnonymous,
      uids: [],
    };

    const baseWithDefaults = assign(defaults, base);

    const userObj = { ...baseWithDefaults } as UserInputModel;

    if (!userObj.uids) userObj.uids = [];
    if (
      fireWithOverrides.uid &&
      !userObj.uids.includes(fireWithOverrides.uid)
    ) {
      userObj.uids.push(fireWithOverrides.uid);
    }

    if (!existing.created) {
      userObj.created = firebase.firestore.FieldValue.serverTimestamp() as any;
    }

    userObj.email = (userObj.email || "").trim().toLowerCase();

    return parseUndefIntoNull(userObj);
  }

  async updateUser(
    userId: string,
    payload: {
      slug?: string;
      displayName?: string;
      email?: string;
      photoFile?: File;
    }
  ) {
    try {
      if (payload.slug) {
        const qs = await this.usersCol
          .where("slug", "==", payload.slug)
          .limit(1)
          .get();
        if (qs.size > 0) throw new UserServiceError("SLUG_ALREADY_EXISTS");
      } else {
        // rm if key is there and is not truthy
        delete payload.slug;
      }

      if (payload.photoFile) {
        const imageId = Date.now() + generateId(16);
        const [fileType] = (payload.photoFile.type || "").split(";");
        const refPath: string = `users/${userId}/photos/raw__${imageId}.${fileType}`;

        (payload as Partial<UserInputModel>).photoURL = await this.uploadImage({
          file: payload.photoFile,
          refPath: refPath,
        });
      }

      if (payload.displayName) {
        (payload as Partial<UserInputModel>).realName = payload.displayName;
      }

      delete payload.photoFile;

      if (payload.email) payload.email = payload.email.trim().toLowerCase();
      await this.usersCol
        .doc(userId)
        .set(parseUndefIntoNull(payload), { merge: true });
    } catch (error) {
      console.error("UserService.updateUser", error);
      throw new UserServiceError("COULD_NOT_UPDATE_USER");
    }
  }

  private async uploadImage({
    file,
    refPath,
  }: {
    file: File;
    refPath: string;
  }): Promise<string> {
    return new Promise((resolve, reject) => {
      const storageRef = this.storage.ref(refPath);
      const [fileType] = (file.type || "").split(";");
      const contentType = `image/${fileType}`;

      const uploadTask = storageRef.put(file, {
        contentType,
        customMetadata: {
          mimeType: contentType,
        },
      });
      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          console.error("UserService.uploadImage", error);
          reject(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  }

  public async getUserDocument(
    by: { id?: string; uid?: string; email?: string }
  ) {
    const { id, uid, email } = by;
    if (!id && !uid && !email) return null;
    if (id) {
      const ds = await this.usersCol.doc(id).get();
      return ds as firebase.firestore.DocumentSnapshot<UnresolvedUser>;
    }
    const qs = await (by.email
      ? this.usersCol.where("email", "==", by.email)
      : this.usersCol.where("uids", "array-contains", by.uid)
    ).get();
    const docs =
      qs.docs as firebase.firestore.QueryDocumentSnapshot<UnresolvedUser>[];
    if (qs.size == 1) return docs[0];
    const primaryUserDoc = this.determinePrimaryUserDoc(docs);
    return primaryUserDoc;
  }
  
  private determinePrimaryUserDoc(
    docs: firebase.firestore.QueryDocumentSnapshot<UnresolvedUser>[]
  ) {
    let primaryUserDoc = docs[0];
    for (const doc of docs) {
      const c = primaryUserDoc.data() || {};
      const u = doc.data() || {};
      // whichever has id prop
      if (u.id && !c.id) primaryUserDoc = doc;
      if (!u.id && c.id) continue;
      // whichever has a paid plan prop
      else if (
        this.paidPlans.includes(u.plan || "") &&
        !this.paidPlans.includes(c.plan || "")
      ) {
        primaryUserDoc = doc;
      } else if (
        !this.paidPlans.includes(u.plan || "") &&
        this.paidPlans.includes(c.plan || "")
      ) {
        continue;
      }
      // whichever is not anonymous prop
      else if (!u.isAnonymous && c.isAnonymous) primaryUserDoc = doc;
      else if (!u.isAnonymous && c.isAnonymous) continue;
      // whichever has realName prop
      else if (u.realName && !c.realName) primaryUserDoc = doc;
      else if (!u.realName && c.realName) continue;
      // whichever has created prop
      else if (u.created && !c.created) primaryUserDoc = doc;
      else if (!u.created && c.created) continue;
      // prioritize oldest user
      else if (u.created < c.created) primaryUserDoc = doc;
    }
    return primaryUserDoc;
  }

  async getUserBySlug(slug: string) {
    try {
      const qs = await this.usersCol.where("slug", "==", slug).get();
      if (qs.size === 0) {
        throw new UserServiceError("COULD_NOT_GET_USER_BY_SLUG");
      }
      return qs.docs[0].data() as UnresolvedUser;
    } catch (error) {
      console.error("UserService.getUserBySlug", error);
      throw new UserServiceError("COULD_NOT_GET_USER_BY_SLUG");
    }
  }

  private async getGravatarDisplayName(emailHash?: string) {
    try {
      if (!emailHash) return "";
      const response = await fetch(
        `https://www.gravatar.com/${emailHash}.json`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          mode: "no-cors",
        }
      );
      if (response.status === 404) return "";
      const responseJson = await response.json();
      return responseJson.entry[0].displayName;
    } catch (error) {
      return "";
    }
  }

  public getRandomDisplayName() {
    return UniqueNameService.generate();
  }

  private getDefaultPhotoURL(displayName: string, emailHash?: string) {
    const fallback = `https://beta.yac.com/avatar/${displayName
      .replace(" ", ".")
      .replace(" ", "-")}`;
    /// Do not delete in case we want to enable gravatar again
    // if (emailHash) {
    //   const path = `${emailHash}?d=${encodeURIComponent(fallback)}`;
    //   return `https://www.gravatar.com/avatar/${path}`;
    // } else {
    //   return fallback;
    // }
    return fallback;
  }
}

export type UserServiceErrorCode =
  | "COULD_NOT_LOAD_USER"
  | "COULD_NOT_UPDATE_USER"
  | "COULD_NOT_GET_USER_BY_SLUG"
  | "INVALID_EMAIL"
  | "SLUG_ALREADY_EXISTS";

export class UserServiceError extends BaseServiceError<UserServiceErrorCode> {
  mapErrorCodeToMessage(Code: UserServiceErrorCode): string {
    switch (Code) {
      case "COULD_NOT_LOAD_USER":
        return "Could not load user details.";
      case "COULD_NOT_UPDATE_USER":
        return "Could not update user.";
      case "COULD_NOT_GET_USER_BY_SLUG":
        return "Could not sign in anonymously.";
      case "SLUG_ALREADY_EXISTS":
        return "Slug already exists.";
      case "INVALID_EMAIL":
        return "Please enter a valid email.";
      default:
        return "There has been an unknown error.";
    }
  }
}
