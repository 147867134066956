export abstract class BaseServiceError<ErrorCode extends string> extends Error {
  public message: string;
  abstract mapErrorCodeToMessage(Code: ErrorCode): string;
  constructor(public code: ErrorCode | "UNKNOWN_ERROR" | "NETWORK_ERROR") {
    super();
    const trueProto = new.target.prototype;
    Object.setPrototypeOf(this, trueProto);
    this.message =
      code === "NETWORK_ERROR"
        ? "There has been a network error."
        : code === "UNKNOWN_ERROR"
        ? "There has been an unknown error"
        : this.mapErrorCodeToMessage(code);
  }
}
