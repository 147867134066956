import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { Miro } from "@mirohq/websdk-types";

// @ts-ignore
import AudioVisual from "vue-audio-visual";
import VueMeta from 'vue-meta';
import VueCompositionAPI from "@vue/composition-api";
import { firestorePlugin } from 'vuefire'
import { app } from "./services/FirebaseService";
import PortalVue from "portal-vue";
import firebase from "firebase/app";
// @ts-ignore

const style = 'color: tomato; background:#eee; -webkit-text-stroke: 1px black; font-size:30px;';
console.log('%cWelcome to YacX Miro Embedded! This is a beta version of our new product, check it out and feel free to let us know what you think! 🎉', style);
if (process.env.NODE_ENV !== 'development') {
  console.log = function () { };
}

Vue.use(PortalVue);
// @ts-ignore
Vue.use(AudioVisual);
Vue.use(firestorePlugin);
Vue.use(VueMeta);
Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

const { board } = window.miro;

new Vue({
  el: "#app",
  store,
  router,
  data() {
    return {
      unsubscribe: null as null | firebase.Unsubscribe,
    };
  },
  render: (h) => h(App),
  created() {
    this.unsubscribe = app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await store.dispatch("user/loadUser");
      } else {
        await store.dispatch("auth/signInAnonymously");
      }
    });
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
});