import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import store, { YacXStore } from "..";
import { Store } from "vuex";
import MeetingService from "@/services/MeetingService";

const meetingService = new MeetingService();

export class MeetingState {
  uploading: boolean = false;
}

class MeetingGetters extends Getters<MeetingState> { }

class MeetingMutations extends Mutations<MeetingState> {
  uploading(current: boolean) {
    this.state.uploading = current;
  }
}

class MeetingActions extends Actions<
  MeetingState,
  MeetingGetters,
  MeetingMutations,
  MeetingActions
> {
  async createMiroMeeting() {
    return meetingService.createMiroMeeting();
  }

  async addMiroRecording(payload: {
    file: File;
    miroBoard: string;
    miroUser: string;
    // onUploadProgress: this.onUploadProgress,
    byUserId: string;
    duration: number;
    // store: this.$store,
    kickoff: boolean;
    type: string;
    meeting: {id: string, slug: string};
  }) {
    await meetingService.addMiroVideo(payload);
  }
}

// Create a module with module asset classes
const MeetingModule = new Module({
  namespaced: true,
  state: MeetingState,
  getters: MeetingGetters,
  mutations: MeetingMutations,
  actions: MeetingActions,
});

export default MeetingModule;
