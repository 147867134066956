import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import firebase from "firebase/app";
import UserService, {
  UserInputModel,
  UserOutputModel,
} from "@/services/UserService";
import LocalStorageService from "@/services/LocalStorageService";
import { validateEmail } from "@/util";
import store from "..";

const userService = new UserService();
const localStorageService = new LocalStorageService();

export class UserState {
  user: UserOutputModel | null = localStorageService.loadBase64("user").user;
  loading: boolean = false;
  resolving: boolean = true;
  error: string | null = null;
  savedUserEmail: string | null =
    localStorageService.loadBase64("savedUserEmail").savedUserEmail;
}

class UserGetters extends Getters<UserState> {
  nonNullUser() {
    return this.state.user || {};
  }
}

class UserMutations extends Mutations<UserState> {
  SET_USER(payload: UserOutputModel | null) {
    this.state.user = payload;
    localStorageService.saveBase64({ user: payload });
  }
  SET_LOADING(payload: boolean) {
    if (!this.state.user) this.state.loading = payload;
    this.state.resolving = payload;
  }
  SET_ERROR(payload: string | null) {
    this.state.error = payload;
  }
  SET_SAVED_USER_EMAIL(payload: string | null) {
    this.state.savedUserEmail = payload;
    localStorageService.saveBase64({ savedUserEmail: payload });
  }
}

class UserActions extends Actions<
  UserState,
  UserGetters,
  UserMutations,
  UserActions
> {
  async loadUser(
    options: {
      prioritizeFirebaseUserFields?: boolean;
      overrides?: Partial<UserInputModel>;
      attemptMergeIntoCurrentUser?: boolean;
      method?: "Google" | "Anonymous" | "NonExpiringMagicLink";
      removeUserWithId?: string;
    } = {}
  ) {
    this.commit("SET_LOADING", true);
    try {
      const firebaseUser = userService.currentUser;
      const savedUserEmail = this.state.savedUserEmail;
      if (!firebaseUser && !savedUserEmail) {
        throw new Error("User is not logged in.");
      }
      const id = this.state.user ? this.state.user.id : undefined;
      const user = await userService.loadUser(
        savedUserEmail
          ? { email: savedUserEmail }
          : (firebaseUser as firebase.User) || {},
        {
          currentUserId: id,
          ...options,
        }
      );
      this.commit("SET_USER", user);
      this.commit("SET_LOADING", false);
      let method:
        | "GOOGLE_LOGIN"
        | "GOOGLE_SIGN_UP"
        | "ANONYMOUS_LOGIN"
        | "ANONYMOUS_SIGNUP"
        | "NON_EXPIRING_MAGIC_LINK_LOGIN" = "ANONYMOUS_LOGIN";
      if (user.created) {
        if (options.method === "Google") method = "GOOGLE_SIGN_UP";
        else method = "ANONYMOUS_SIGNUP";
      } else {
        if (options.method === "Google") method = "GOOGLE_LOGIN";
        else if (options.method === "NonExpiringMagicLink") {
          method = "NON_EXPIRING_MAGIC_LINK_LOGIN";
        }
      }

      store.dispatch("auth/loggedIn");
    } catch (error: any) {
      this.commit("SET_LOADING", false);
      this.commit("SET_ERROR", error.message || "Could not load user.");
      throw error;
    }
  }

  async updateUser(payload: {
    slug?: string;
    displayName?: string;
    email?: string;
  }) {
    if (!this.state.user) throw new Error("User is not logged in.");
    await userService.updateUser(this.state.user.id, payload);
    await this.dispatch("loadUser");
  }

  async setSavedUserEmail(payload: {
    email: string;
    nonExpiringMagicLink?: boolean;
    removeUserWithId?: string;
  }): Promise<unknown> {
    if (this.state.savedUserEmail === payload.email) return;
    if (payload.email && !validateEmail(payload.email)) {
      throw new Error("Invalid email.");
    }
    const currentEmail = this.state.user ? this.state.user.email : "";
    this.commit("SET_SAVED_USER_EMAIL", payload.email);
    if (payload.email) {
      return this.dispatch("loadUser", {
        attemptMergeIntoCurrentUser: currentEmail ? false : true,
        method: payload.nonExpiringMagicLink
          ? "NonExpiringMagicLink"
          : undefined,
        removeUserWithId: payload.removeUserWithId,
      });
    }
  }

  async getUserBySlug({ slug }: { slug: string }) {
    return await userService.getUserBySlug(slug);
  }

  clear() {
    this.commit("SET_USER", null);
    this.commit("SET_ERROR", null);
    this.commit("SET_LOADING", false);
    this.commit("SET_SAVED_USER_EMAIL", null);
  }
}

// Create a module with module asset classes
const UserModule = new Module({
  namespaced: true,
  state: UserState,
  getters: UserGetters,
  mutations: UserMutations,
  actions: UserActions,
});

export default UserModule;
