import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import { injectMetadata } from "@/util";
import store from "..";
import { commit, dispatch } from "vuex-smart-module/lib/context";
import { BaseServiceError } from "@/services/BaseServiceError";
import vueCompositionApi from "@vue/composition-api";
import { createNamespacedHelpers } from "vuex";

export class RecordingState {
    isRecording = false;
    readyForUpload = false;
    duration = 0;

    recordScreen = false;
    mediaRecorder: null | MediaRecorder = null;
    stream: null | MediaStream = null;

    recordedData: null | Blob = null;
    recorderInterval: number | null = null;

    devices: InputDeviceInfo[] = [];
    defaultDeviceId: string = "default";

    miroBoardId:string | null = null;
    miroUserId:string | null = null;

    isSafari = false;

    permissions: {
        microphone: PermissionState | null
    } = {
        microphone: null
    };
}

class RecordingGetters extends Getters<RecordingState> {
    async getEncodedFile():Promise<File>{
        if(this.state.recordedData == null) throw new Error("Missing recorded data.");

        let fileWithMetadata = this.state.recordedData;
        if (
            injectableTypes.includes(this.state.recordedData.type) ||
            this.state.recordedData.type.includes("video/x-matroska")
        ) {
            fileWithMetadata = await injectMetadata(
                this.state.recordedData
            ).catch((err) => {
                console.error(err);
                throw new Error("Error generating file.");
            });
        }

        const file = new File(
            [fileWithMetadata],
            this.state.recordedData.type.includes("mp4")
            ? "file.mp4"
            : "file.webm",
            {
            type: this.state.recordedData.type,
            lastModified: Date.now(),
            }
        );
        
        return file;
    }
}

class RecordingMutations extends Mutations<RecordingState> {
    AUDIO_DEVICES(mics: InputDeviceInfo[]){
        this.state.devices = mics;
        if(this.state.defaultDeviceId != "default"){
            const found = this.state.devices.find(device=>device.deviceId === this.state.defaultDeviceId);
            if(found === undefined){
                this.state.defaultDeviceId = "default";
            }
        }
    }

    CLEAR_DURATION(){
        this.state.duration = 0;
    }

    CLEAR_STREAM(){
        if(this.state.stream != null){
            this.state.stream.getTracks().forEach((t: any) => t.stop());   
            this.state.stream = null;
        }
    }

    DEFAULT_AUDIO_DEVICE(defaultDeviceId: string){
        this.state.defaultDeviceId = defaultDeviceId;
    }

    MIC_PERMISSION(state: PermissionState){
        this.state.permissions.microphone = state;
    }

    MIRO_BOARD(miroBoardId: string){
        this.state.miroBoardId = miroBoardId;
    }

    MIRO_USER(miroUserId: string){
        this.state.miroUserId = miroUserId;
    }

    RESET(){
        this.state.duration = 0;
        if(this.state.stream != null){
            this.state.stream.getTracks().forEach((t: any) => t.stop());   
            this.state.stream = null;
        }
        this.state.mediaRecorder = null;
        this.state.isRecording = false;
        this.state.readyForUpload = false;
    }

    SAFARI(){
        this.state.isSafari = true;
    }

    START_RECORD(stream:MediaStream){
        this.state.readyForUpload = false;
        this.state.stream = stream;
        this.state.mediaRecorder = new MediaRecorder(this.state.stream);
        if(this.state.mediaRecorder){
        
            // MediaRecorder events
            this.state.mediaRecorder.ondataavailable = (e: any) => {
                const { data } = e;
                this.state.recordedData = data;
                this.state.readyForUpload = true;
            };
    
            this.state.mediaRecorder.onstart = () => {
                if (this.state.recorderInterval) return;
                if(this.state.recorderInterval == null){
                    this.state.recorderInterval = window.setInterval(() => {
                        this.state.duration++;
                    }, 1000);
                }
            };
    
            this.state.mediaRecorder.onstop = () => {
                if(this.state.recorderInterval) clearInterval(this.state.recorderInterval);
                this.state.recorderInterval = null;
            };

            if(this.state.stream != null){
                const videoTracks = this.state.stream.getVideoTracks();
                if (videoTracks.length > 0) {
                    videoTracks[0].onended = () => {
                        if(this.state.mediaRecorder){
                            this.state.mediaRecorder.stop();
                            this.state.isRecording = false;
                        }
                    };
                }
            }
        
            this.state.mediaRecorder.start();
            this.state.isRecording = true;
        }
    }

    SCREEN_RECORDING(active:boolean){
        this.state.recordScreen = active;
    }

    STOP_RECORD(){
        this.state.isRecording = false;
        
        if(this.state.mediaRecorder != null){
            this.state.mediaRecorder.stop();
        }
          
        if(this.state.stream != null){
            this.state.stream.getTracks().forEach((t: any) => t.stop());
            this.state.stream = null;
        }
    }

    SET_RECORDING(recording: boolean){
        this.state.isRecording = recording;
    }
}

class RecordingActions extends Actions<
  RecordingState,
  RecordingGetters,
  RecordingMutations,
  RecordingActions
> {

    discardRecording(){
        if(this.state.isRecording){
            if(this.state.mediaRecorder){
                this.state.mediaRecorder.ondataavailable = ()=>{};
            }
            this.commit("STOP_RECORD");
            this.commit("RESET");
        }
    }

    stopRecording(){
        this.commit("STOP_RECORD");
    }

    reset(){
        this.commit("RESET");
    }

    async startScreenRecording(){
        this.commit("SCREEN_RECORDING", true);
        const stream = await getStreams(this.state.defaultDeviceId, this.state.recordScreen)
            .catch((err:RecordingModuleError)=>{
                throw new RecordingModuleError(err.code)
            });
        this.commit("START_RECORD", stream);
    }

    async startAudioRecording(){
        this.commit("SCREEN_RECORDING", false);
        const stream = await getStreams(this.state.defaultDeviceId, this.state.recordScreen)
            .catch((err:RecordingModuleError)=>{
                throw new RecordingModuleError(err.code)
            });
        this.commit("START_RECORD", stream);

        //TODO Add to clients that support enableNoSleep
        // document.addEventListener(
        //   "click",
        //   function enableNoSleep() {
        //     document.removeEventListener("click", enableNoSleep, false);
        //     that.noSleep.enable();
        //   },
        //   false
        // );
    }

    async loadSources(){
        const devices = await navigator.mediaDevices.enumerateDevices();
        const inputDevices:InputDeviceInfo[] = devices.filter(device=> device.kind==="audioinput" && device.deviceId.length > 0) as InputDeviceInfo[];
        this.commit("AUDIO_DEVICES", inputDevices);
    }

    selectDefaultDevice(defaultDeviceId:string){
        const found = this.state.devices.find((device)=>device.deviceId == defaultDeviceId);
        if(found != undefined){
            this.commit("DEFAULT_AUDIO_DEVICE", defaultDeviceId);
        }
    }

    microphonePermission(state: PermissionState){
        this.commit("MIC_PERMISSION", state);

        if(state === 'granted'){
            this.dispatch("loadSources");
        }else{
            this.commit("AUDIO_DEVICES", []);
        }
    }

    safariPermission(access: boolean){
        this.commit("SAFARI", access);
        this.dispatch("loadSources");
    }

    miroSettings(miroBoardId: string, miroUserId: string){
        this.commit("MIRO_BOARD", miroBoardId);
        this.commit("MIRO_USER", miroUserId);
    }
}

// Create a module with module asset classes
const RecordingModule = new Module({
  namespaced: true,
  state: RecordingState,
  getters: RecordingGetters,
  mutations: RecordingMutations,
  actions: RecordingActions,
});

export default RecordingModule;

 // recorder-related
 async function getStreams(audioInputDevice: string, recordScreen: boolean) {
    const constraints = (audioInputDevice === "default")? {audio: true}: {
        audio: {deviceId: audioInputDevice ? {exact: audioInputDevice} : "default"}
    };

    if (recordScreen) {
        try{

            // @ts-ignore-next-line
            const displayStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
            }).catch(()=> {
                throw new RecordingModuleError("COULD_NOT_GET_VIDEO_STREAM");
            });

            const audioStream = await navigator.mediaDevices.getUserMedia(constraints).catch(()=> {
                displayStream.getVideoTracks().forEach((track:any)=>track.stop());
                throw new RecordingModuleError("COULD_NOT_GET_AUDIO_STREAM");
            });
            
            const audioTrack = audioStream.getAudioTracks()[0];
            displayStream.addTrack(audioTrack);

            return displayStream;

        }catch(err:any){
            throw new RecordingModuleError(err.code);
        }
    } else {
        try{
            return await navigator.mediaDevices.getUserMedia(constraints);
        } catch(err) {
            throw new RecordingModuleError("COULD_NOT_GET_AUDIO_STREAM");
        }
    }
}

const injectableTypes = [
  "video/webm",
  "audio/webm",
  "video/webm;codecs=vp8",
  "video/webm;codecs=daala",
  "video/webm;codecs=h264",
  "video/webm;codecs=avc1",
  "audio/webm;codecs=opus",
  "video/webm;codecs=h264,opus",
  "video/webm;codecs=h264,vp9,opus",
  "video/webm;codecs=vp8,opus",
  "video/mpeg",
  "video/x-matroska;codecs=avc1,opus",
  "video/x-matroska;codecs=avc1",
  "video/x-matroska;codecs=avc1.42000c",
  "video/x-matroska;codecs=avc1.4d000c",
  "video/x-matroska;codecs=avc1.64000c",
  "video/x-matroska;codecs=avc1.640029",
  "video/x-matroska;codecs=avc1.640034",
];


export type RecordingModuleErrorCode =
  | "COULD_NOT_GET_AUDIO_STREAM"
  | "COULD_NOT_GET_VIDEO_STREAM"
export class RecordingModuleError extends BaseServiceError<RecordingModuleErrorCode>{
    mapErrorCodeToMessage(Code: RecordingModuleErrorCode): string {
        switch (Code) {
          case "COULD_NOT_GET_AUDIO_STREAM":
            return "Could not get permissions for user's mic.";
          case "COULD_NOT_GET_VIDEO_STREAM":
            return "Could not get permissions for user's screen.";
          default:
            return "There has been an unknown error.";
        }
      }
}