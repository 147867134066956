














import "@/assets/tailwind.css";
import "@/assets/appearance.css";
import Vue from "vue";
import { getAuthLoadingMessage } from "@/util";
import { mapState } from "vuex";

export default Vue.extend({
  name: "App",
  metaInfo: {
    title: 'YacX'
  },
  data() {
    return {
      loadingMessage: getAuthLoadingMessage(),
      interval: null as any,
    };
  },
  computed: {
    ...mapState("user", { userLoading: "resolving" }),
  },
});
