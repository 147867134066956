import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "@/views/Login.vue";
import Record from "@/views/Record.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: Record },
    // { path: "/new", component: Record },
  ],
});

export default router;
