import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBjZ_M9m4YHlH4cAfitY_DnCeqm4ArRDZU",
  authDomain: "notzoom-339502.firebaseapp.com",
  projectId: "notzoom-339502",
  storageBucket: "notzoom-339502.appspot.com",
  messagingSenderId: "970453951309",
  appId: "1:970453951309:web:d9fdcda7497862f10a4d04",
  measurementId: "G-HD5JTXRM2X",
};

export const app = firebase.initializeApp(firebaseConfig);

// if (location.hostname === 'localhost') {
//   app.firestore().useEmulator('localhost', 9090);
//   app.storage().useEmulator('localhost', 9199);
// }

export default class FirebaseService {
  auth = app.auth();
  db = app.firestore();
  storage = app.storage();
  providers = {
    Google: firebase.auth.GoogleAuthProvider,
    EmailAndPassword: firebase.auth.EmailAuthProvider,
  };
  meetingsCol = this.db.collection("meetings");
  usersCol = this.db.collection("users");
  public get currentUser() {
    return this.auth.currentUser;
  }
}

function connectFirestoreEmulator(db: any, arg1: string, arg2: number) {
  throw new Error("Function not implemented.");
}

